import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Layout from "../Components/Layout/Layout";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, ErrorMessage, Form as FormikForm } from "formik";
import * as Yup from "yup";
import AdminActions from "../Redux/Actions/AdminActions";
import { toast } from "react-toastify";
import { startstopLoading } from "../Redux/Reducers/globalSlice";
import DeleteModal from "../Components/CommanModals/DeleteModal";

export default function Faqs() {
  const { faqs } = useSelector((state) => state.adminAuth)
  const dispatch = useDispatch()
  const location = useLocation();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentFaq, setCurrentFaq] = useState({});
  const [faqToDelete, setFaqToDelete] = useState(null);

  const totalEntries = faqs?.total ?? 0;
  const entriesPerPage = 10;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  const validationSchema = Yup.object({
    title: Yup.string().required("title is required"),
    description: Yup.string().required("description is required"),
  });

  const initialValues = {
    title: currentFaq?.title || "",
    description: currentFaq?.description || "",
  };

  const handleSubmit = async (values) => {
    try {
      const action = currentFaq?._id ? AdminActions.editFaq : AdminActions.createFaq;
      const actionMessage = currentFaq?._id ? "FAQ updated successfully!" : "FAQ created successfully!"
      if (currentFaq?._id) values['id'] = currentFaq?._id
      await dispatch(action(values));
      Initialize();
      toast.success(actionMessage);
    } catch (error) {
      toast.error(error.message || "An error occurred while processing the request.");
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleOpenDeleteModal = (id) => {
    setFaqToDelete(id);
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleDeletePreset = () => {
    dispatch(AdminActions.deleteFaq({ id: faqToDelete })).then((res) => {
      if (res?.payload?.statusCode == 200) {
        Initialize()
        toast.success("Deleted Successfully")
      }
      else {
        toast.error(res?.payload?.message)
      }
      handleCloseDeleteModal();
    })
  }



  const Initialize = async () => {
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get("search") || "";
    const queryParams = searchQuery ? { search: searchQuery } : { page: currentPage, limit: 10 };
    dispatch(startstopLoading(true));
    await dispatch(AdminActions.getFaq(queryParams)).then((res) => {
      dispatch(startstopLoading(false));
      handleClose()
    });
  }

  useEffect(() => {
    Initialize()
  }, [location.search, location.pathname, currentPage]);

  const handleEdit = (res) => {
    setCurrentFaq(res)
    handleShow()
  }

  return (
    <Layout>
      <Container fluid>
        <div className="comn-table-title">
          <h3>
            FAQs <span>({faqs?.faq?.length})</span>
          </h3>
          <button onClick={() => {
            setCurrentFaq({})
            handleShow()
          }}>Add New</button>
        </div>
        <div className="songlist-selection mt-3">
          <div className="my-profile-title">
            <h4>FAQs</h4>
          </div>
          <hr />
          <div className="faq-list-accro">
            <Accordion defaultActiveKey="0" flush>
              {faqs?.faq?.map((faqRes, index) =>
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>
                    {faqRes?.title}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="delete-icon-ad">
                      {faqRes?.description}
                      <div className="d-flex gap-1">
                        <button onClick={() => handleEdit(faqRes)}>
                          <img src={require("../Assets/Images/edit.svg").default} />
                        </button>
                        <button onClick={() => handleOpenDeleteModal(faqRes?._id)}>
                          <img src={require("../Assets/Images/delete.svg").default} />
                        </button>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </Accordion>
          </div>
        </div>
        <div className="mt-0">
          <p className="pb-4"></p>
        </div>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">{currentFaq?._id ? 'Edit' : 'Add'} FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ handleSubmit, errors, isValid }) => (
                <FormikForm onSubmit={handleSubmit}>
                  <div className="modal-cmn-form-set">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>FAQ Title</Form.Label>
                      <Field
                        name="title"
                        type="text"
                        className="form-control"
                        placeholder="FAQ Title"
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Description</Form.Label>
                      <Field
                        as="textarea"
                        name='description'
                        className='form-control'
                        rows={3}
                        placeholder="Enter Description"
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </div>
                  <Modal.Footer className="border-none justify-content-center">
                    <button className="secondary" onClick={handleClose} type="button" >
                      Cancel
                    </button>
                    <button className="primary"
                      type="submit"
                    >
                      {currentFaq?._id ? 'Edit' : 'Add'}
                    </button>
                  </Modal.Footer>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Modal.Body>

      </Modal>
      <DeleteModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDeletePreset}
        name={"FAQ"}
      />
    </Layout>
  );
}
