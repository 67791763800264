import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useDispatch, useSelector } from "react-redux";
import { startstopLoading } from "../Redux/Reducers/globalSlice";
import { getEvents } from "../Redux/Actions/event";
import CreateEventModal from "../Components/CommanModals/CreateEventModal";
import { Encryptedid } from "../Utils/bcrypt";
import { capitalize, formatTimeCalenderEvent } from "../Utils/commonFiles";
import { setVisibleRange } from "../Redux/Reducers/eventSlice";
import moment from "moment/moment";


export default function Events() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const calendarRef = useRef(null);

  const eventList = useSelector((e) => e.eventAuth.eventsList)
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [lastVisibleRange, setLastVisibleRange] = useState(null);

  const handleDateClick = (arg) => {
    // alert(arg.dateStr);
  };
  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  // useEffect(() => {
  //   dispatch(startstopLoading(true));
  //   dispatch(getEvents({ page: 0, limit: 10 })).then((res) => {
  //     dispatch(startstopLoading(false));
  //   });
  // }, [dispatch]);

  useEffect(() => {
    if (eventList?.event?.length) {
      const transformedEvents = eventList?.event?.map((event) => {
        const eventDate = moment(event.eventDate);
        const fromTime = moment(event.eventTime.from);
        const toTime = moment(event.eventTime.to);
        const start = eventDate
          .clone()
          .set({
            hour: fromTime.hour(),
            minute: fromTime.minute(),
            second: fromTime.second(),
            millisecond: 0,
          });

        const end = eventDate
          .clone()
          .set({
            hour: toTime.hour(),
            minute: toTime.minute(),
            second: toTime.second(),
            millisecond: 0,
          });
        if (end.isBefore(start)) {
          end.add(1, 'day');
        }
        return {
          id: event._id,
          title: event.eventName,
          start: start.toDate(),
          end: end.toDate(),
          extendedProps: {
            location: event.location,
            musician: event.musician,
            price: event.price,
            client: event.client._id,
          },
        };
      });

      setEvents(transformedEvents);
    }
  }, [eventList]);

  const handleEventDetail = (data) => {
    navigate(`/events-details/${Encryptedid(data?.id)}?clientId=${data?.extendedProps.client}`)
  }

  const renderEventContent = (eventInfo) => {
    const { start, end, title, extendedProps } = eventInfo.event;
    console.log(eventInfo.event, 'x');


    const startDate = new Date(start);
    const endDate = new Date(end);

    const startTime = formatTimeCalenderEvent(startDate);
    const endTime = formatTimeCalenderEvent(endDate);

    return (
      <div onClick={() => handleEventDetail(eventInfo.event)} style={{ cursor: 'pointer' }} className="w-100">
        <div className="d-flex justify-content-between">
          <b>{capitalize(title)}</b>
          <div>
            <i>{`At ${capitalize(extendedProps?.location)}`}</i>
          </div>
        </div>
        <div>
          {extendedProps?.musician.fullName || 'Bianca'}
        </div>
        <div>
          {startTime} - {endTime}
        </div>
      </div>
    );
  };


  const handleDatesSet = (arg) => {
    const startDate = arg.start;
    const endDate = arg.end;

    const visibleStart = new Date(startDate).toISOString().split("T")[0];
    const visibleEnd = new Date(endDate).toISOString().split("T")[0];
    dispatch(setVisibleRange({ start: visibleStart, end: visibleEnd }));

    if (!lastVisibleRange || lastVisibleRange.start !== visibleStart || lastVisibleRange.end !== visibleEnd) {
      dispatch(startstopLoading(true));
      dispatch(getEvents({ startOfMonth: visibleStart, endOfMonth: visibleEnd })).then(() => {
        dispatch(startstopLoading(false));
      });
      setLastVisibleRange({ start: visibleStart, end: visibleEnd });
    }
  };

  return (
    <Layout>
      <Container fluid className="main-table-box">
        <div className="comn-table-title ">
          <button onClick={handleOpen}>Create Event</button>
        </div>
        <div className="schedule_main">
          <div className="inner-filter-field">
            <FullCalendar
              timeZone="local"
              ref={calendarRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: "prev,next",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              initialView="dayGridMonth"
              dateClick={(e) => handleDateClick(e)}
              events={events}
              eventContent={renderEventContent}
              editable={true}
              weekends={true}
              eventTimeFormat={{
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              }}
              validRange={{
                start: new Date().toISOString().split('T')[0],
              }}
              datesSet={handleDatesSet}
            />
          </div>
        </div>
        <div className="mb-0">
          <p className="pb-4"></p>
        </div>
      </Container>
      <CreateEventModal show={showModal} handleClose={handleClose} />
    </Layout>
  );
}
