import React, { useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getAllClients } from "../../Redux/Actions/auth";
import { eventType } from "../../Utils/commonFiles";
import {
  createEvent,
  editEvent,
  getClientsEvents,
  getEvents,
  getMusician,
} from "../../Redux/Actions/event";
import { toast } from "react-toastify";
import { presetsAllAction } from "../../Redux/Actions/presets";
import Select from "react-select";

const CreateEventModal = ({ id, show, handleClose, selectedEvent }) => {
  const dispatch = useDispatch();
  const getInfo = useSelector((e) => e.userAuth.adminDetail);
  const visibleRange = useSelector((state) => state.eventAuth.visibleRange);
  const MusicianList = useSelector((e) => e.eventAuth.MusiciansList)

  const validationSchema = Yup.object({
    client: Yup.string().required("Client is required"),
    // date: Yup.date().required("Date is required"),
    date: Yup.date()
      .required("Date is required")
      .min(
        new Date().toISOString().split("T")[0],
        "Enter a valid year"
      )
      .test(
        "reasonable-year",
        "Year must be a 4-digit number",
        (value) => {
          if (!value) return false; // Should not allow null or empty value
          const year = new Date(value).getFullYear();
          return year >= 1900 && year <= 2100; // Define your range
        }
      ),
    startTime: Yup.string().required("Start time is required"),
    endTime: Yup.string().required("End time is required"),
    eventName: Yup.string().required("Event name is required"),
    location: Yup.string().required("Location is required"),
    musician: Yup.string().required("Musician is required"),
    // price: Yup.number()
    //   .typeError("Price must be a number")
    //   .positive("Price must be positive")
    //   .required("Price is required"),
    price: Yup.number()
      .typeError("Price must be a number")
      .positive("Price must be positive")
      .max(10000, "Price cannot exceed 10000")
      .required("Price is required"),
  });

  const getEventTypeKey = (label) => {
    return Object.keys(eventType).find((key) => eventType[key] === label) || "";
  };

  const formik = useFormik({
    initialValues: {
      client: selectedEvent?.client || "",
      date: selectedEvent?.eventDate?.split("T")[0] || "",
      startTime:
        selectedEvent?.eventTime?.from?.split("T")[1]?.slice(0, 5) || "",
      endTime: selectedEvent?.eventTime?.to?.split("T")[1]?.slice(0, 5) || "",
      eventName: selectedEvent?.eventName || "",
      location: selectedEvent?.location || "",
      musician: selectedEvent?.musician || "",
      price: selectedEvent?.price || "",
      eventType: selectedEvent?.eventType?._id || "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const formattedData = {
        client: values.client,
        eventDate: values.date,
        eventTime: {
          from: new Date(
            `${values.date}T${values.startTime}:00Z`
          ).toISOString(),
          to: new Date(`${values.date}T${values.endTime}:00Z`).toISOString(),
        },
        eventType: values?.eventType,
        eventName: values.eventName,
        location: values.location,
        musician: values.musician,
        price: parseFloat(values.price),
      };
      if (selectedEvent?._id) {
        dispatch(editEvent({ id: selectedEvent._id, ...formattedData })).then(
          (res) => {
            if (res?.payload?.statusCode === 200) {
              toast.success("Event Updated Successfully");
              if (id) {
                dispatch(getClientsEvents({ page: 0, limit: 5, clientId: id }));
              }
              formik.resetForm();
              handleClose();
            } else {
              toast.error(res?.payload?.message);
            }
          }
        );
      } else {
        dispatch(createEvent(formattedData)).then((res) => {
          if (res?.payload?.statusCode === 200) {
            toast.success("Event Created Successfully");
            if (id) {
              dispatch(getClientsEvents({ page: 0, limit: 5, clientId: id }));
            }
            if (visibleRange) {
              dispatch(getEvents({ startOfMonth: visibleRange.start, endOfMonth: visibleRange.end }))
            }
            formik.resetForm();
            handleClose();
          } else {
            toast.error(res?.payload?.message);
          }
        });
      }
    },
  });

  const getClientsList = useSelector((e) => e.userAuth.clientList);
  const listAllEvent = useSelector((e) => e.presetAuth.presetAllList);
  const getAllClientsList = useSelector((e) => e.userAuth.allClientList);

  useEffect(() => {
    dispatch(getAllClients());
    dispatch(presetsAllAction());
    dispatch(getMusician())
  }, [dispatch]);

  useEffect(() => {
    if (id && show) {
      formik.setFieldValue("client", id);
    }
  }, [id, show]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">
            {selectedEvent?._id ? "Update Event" : "Create Event"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <Form onSubmit={formik.handleSubmit}>
            <div className="modal-cmn-form-set">
              {/* Client */}
              <Form.Group className="mb-3" controlId="client">
                <Form.Label>Client</Form.Label>
                <Select
                  id="category"
                  className="custom-select"
                  name="client"
                  options={getAllClientsList?.map((elem) => ({
                    value: elem?._id,
                    label: elem?.fullName,
                  }))}
                  value={getAllClientsList
                    ?.map((elem) => ({
                      value: elem?._id,
                      label: elem?.fullName,
                    }))
                    .find((option) => option.value === formik.values.client)}
                  onChange={(selectedOption) =>
                    formik.setFieldValue("client", selectedOption?.value || "")
                  }
                  onBlur={() => formik.setFieldTouched("client", true)}
                  placeholder="Select Client"
                />
                {formik.touched.client && formik.errors.client && (
                  <div className="text-danger">{formik.errors.client}</div>
                )}
              </Form.Group>

              {/* Date */}
              <Form.Group className="mb-3" controlId="date">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  name="date"
                  value={formik.values.date}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  min={new Date().toISOString().split("T")[0]}
                />
                {formik.touched.date && formik.errors.date && (
                  <div className="text-danger">{formik.errors.date}</div>
                )}
              </Form.Group>

              {/* Time */}
              <Form.Group className="mb-3" controlId="time">
                <Form.Label>Time</Form.Label>
                <div className="d-flex align-items-center gap-3">
                  <Form.Control
                    type="time"
                    name="startTime"
                    value={formik.values.startTime}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <p className="m-0">To</p>
                  <Form.Control
                    type="time"
                    name="endTime"
                    value={formik.values.endTime}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.startTime && formik.errors.startTime && (
                  <div className="text-danger">{formik.errors.startTime}</div>
                )}
                {formik.touched.endTime && formik.errors.endTime && (
                  <div className="text-danger">{formik.errors.endTime}</div>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="eventName">
                <Form.Label>Event Name</Form.Label>
                <Form.Control
                  type="text"
                  name="eventName"
                  value={formik.values.eventName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter name"
                />
                {formik.touched.eventName && formik.errors.eventName && (
                  <div className="text-danger">{formik.errors.eventName}</div>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="eventType">
                <Form.Label>Event Type</Form.Label>
                <Form.Select
                  id="category"
                  name="eventType"
                  value={formik.values.eventType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      const selectedOption = e.target.selectedOptions[0];
                      if (selectedOption) {
                        formik.setFieldValue("eventType", selectedOption.value);
                      }
                    }
                  }}
                >
                  <option value="">Select Event Type</option>
                  {listAllEvent?.map((elem, i) => (
                    <option key={i} value={elem?._id}>
                      {elem?.eventType}
                    </option>
                  ))}
                </Form.Select>
                {formik.touched.eventType && formik.errors.eventType && (
                  <div className="text-danger">{formik.errors.eventType}</div>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="location">
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type="text"
                  name="location"
                  value={formik.values.location}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Search location"
                />
                {formik.touched.location && formik.errors.location && (
                  <div className="text-danger">{formik.errors.location}</div>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="musician">
                <Form.Label>Musician</Form.Label>
                <Form.Select
                  id="category"
                  name="musician"
                  value={formik.values.musician}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      const selectedOption = e.target.selectedOptions[0];
                      if (selectedOption) {
                        formik.setFieldValue("musician", selectedOption.value); // Update Formik value
                      }
                    }
                  }}
                >
                  <option value="">Select Musician</option>
                  {MusicianList?.map((elem) => (
                    <option value={elem?._id}>{elem?.fullName}</option>
                  ))}
                </Form.Select>
                {formik.touched.musician && formik.errors.musician && (
                  <div className="text-danger">{formik.errors.musician}</div>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="price">
                <Form.Label>Price</Form.Label>
                <div className="ad-dollor">
                  <span className="input-prefix">£</span>
                  <Form.Control
                    type="text"
                    name="price"
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder=" Enter price"
                  />
                </div>
                {formik.touched.price && formik.errors.price && (
                  <div className="text-danger">{formik.errors.price}</div>
                )}
              </Form.Group>

            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-none justify-content-center">
          <button className="secondary" onClick={handleClose}>
            Close
          </button>
          <button
            type="submit"
            className="primary"
            onClick={formik.handleSubmit}
          >
            {selectedEvent?._id ? "Update" : "Create"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateEventModal;
