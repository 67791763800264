import React from "react";
import { Form, Modal } from "react-bootstrap";
import { Formik, Field, ErrorMessage, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const validationSchema = Yup.object({
  fullName: Yup.string().required("full name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("email is required"),
  //  eventDate: Yup.date().nullable(),
  eventDate: Yup.date()
  .nullable()
  .test(
    "reasonable-year",
    "Year must be within a reasonable range",
    (value) => {
      if (!value) return true; // If the date is null, let it pass (handled by .nullable())
      const year = new Date(value).getFullYear();
      return year > 1900 && year < 2100; // Define your reasonable range
    }
  ),
  notes: Yup.string(),
});

const CreateClientModal = ({ show, handleClose, handleCreateClient, selectedClient }) => {

  const initialValues = {
    // id: selectedClient?._id || "",
    fullName: selectedClient?.fullName || "",
    email: selectedClient?.email || "",
    eventDate: selectedClient?.eventDate?.split("T")[0] || "",
    notes: selectedClient?.notes || "",
  };


  const handleSubmit = async (values, { setSubmitting }) => {
    const { email, ...updatedValues } = values; 
    const payload = selectedClient
      ? { id: selectedClient._id, ...updatedValues } 
      : values;


    try {
      await handleCreateClient(payload);
      // handleClose();
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header className="border-none justify-content-center">
        <Modal.Title>{selectedClient ? "Client Details" : "Create Client"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ handleSubmit, errors, isValid }) => (
            <FormikForm onSubmit={handleSubmit}>
              <div className="modal-cmn-form-set">
                <Form.Group className="mb-3" controlId="formFullName">
                  <Form.Label>Client Name*</Form.Label>
                  <Field
                    name="fullName"
                    type="text"
                    className="form-control"
                    placeholder="Enter Client Name"
                  />
                  <ErrorMessage
                    name="fullName"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label>Email*</Form.Label>
                   <Field
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Enter Client Email"
                    disabled={selectedClient?._id} 
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formeventDate">
                  <Form.Label>Estimated Event Date</Form.Label>
                  <Field
                    name="eventDate"
                    type="date"
                    className="form-control"
                    placeholder="Select date"
                    min={new Date().toISOString().split("T")[0]}
                    max="2099-12-31"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formNotes">
                  <Form.Label>Notes</Form.Label>
                  <Field
                    name="notes"
                    as="textarea"
                    rows={3}
                    className="form-control"
                    placeholder="Enter Notes"
                  />
                </Form.Group>
              </div>
              <Modal.Footer className="border-none justify-content-center">
                <button
                  className="secondary"
                  type="button"
                  onClick={handleClose}
                >
                  Close
                </button>
                <button className="primary" type="submit">
                 {selectedClient ? "Update" : "Create"}
                </button>
              </Modal.Footer>
            </FormikForm>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CreateClientModal;
