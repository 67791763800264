import React from "react";
import { Modal, Form } from "react-bootstrap";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { createSongs, editSong, getSongs } from "../../Redux/Actions/songs";
import { toast } from "react-toastify";

const CreateSongsModal = ({ show, handleClose, selectedSong }) => {
    const dispatch = useDispatch()
    const initialValues = {
        genre: selectedSong?.genre || "",
        artist: selectedSong?.artist || "",
        title: selectedSong?.title || "",
        // recomended: selectedSong?.recomended || false,
        // duration: selectedSong?.duration || "",
    };

    const validationSchema = Yup.object({
        genre: Yup.string().required("Genre is required"),
        artist: Yup.string().required("Artist name is required"),
        title: Yup.string().required("Song title is required"),
        // recomended: Yup.boolean(),
        // duration: Yup.string()
        // .matches(/^([0-9]{2}):([0-9]{2})$/, "Duration must be in HH:MM format")
        // .required("Duration is required"),
        // duration: Yup.string()
        //     .matches(/^([0-9]{1,2}):([0-5][0-9])$/, "Duration must be in MM:SS or MM:SS format")
        //     .required("Duration is required"),

        // duration: Yup.string()
        //     .required("Duration is required")
        //     .matches(
        //         /^([0-5]?[0-9]):([0-5][0-9])$/,
        //         "Duration must be in MM:SS format"
        //     )
        //     .test(
        //         "valid-minutes",
        //         "Minutes must be between 0 and 59",
        //         (value) => {
        //             const [minutes] = (value || "").split(":");
        //             return !minutes || (parseInt(minutes, 10) >= 0 && parseInt(minutes, 10) < 60);
        //         }
        //     )
        //     .test(
        //         "valid-seconds",
        //         "Seconds must be between 0 and 59",
        //         (value) => {
        //             const [, seconds] = (value || "").split(":");
        //             return !seconds || (parseInt(seconds, 10) >= 0 && parseInt(seconds, 10) < 60);
        //         }
        //     )


    });

    const handleSubmit = (values) => {
        const payload = selectedSong
            ? { id: selectedSong._id, ...values }
            : values;

        const action = selectedSong ? editSong : createSongs;

        dispatch(action(payload)).then((res) => {
            if (res?.payload?.statusCode === 200) {
                toast.success(
                    selectedSong
                        ? "Song Updated Successfully"
                        : "Song Created Successfully"
                );
                dispatch(getSongs({ page: 0, limit: 10 }));
                handleClose();
            } else {
                toast.error(res?.payload?.message || "Something went wrong");
            }
        });
    };

    const genreOptions = {
        'Rock': "Rock",
        'Pop': "Pop",
        'Hip Hop': "Hip_hop",
        'Jazz': "Jazz",
        'Classical': "Classical",
    };

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header className="border-none justify-content-center">
                <Modal.Title className="mt-3">{selectedSong?._id ? "Update Song" : "Add Song"}</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue }) => (
                    <FormikForm>
                        <Modal.Body className="py-0">
                            <div>
                                <Form.Group className="mb-3">
                                    <Form.Label>Song Title</Form.Label>
                                    <Field
                                        type="text"
                                        name="title"
                                        className="form-control"
                                        placeholder="Enter song title"
                                    />
                                    <ErrorMessage
                                        name="title"
                                        component="div"
                                        className="text-danger"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Artist</Form.Label>
                                    <Field
                                        type="text"
                                        name="artist"
                                        className="form-control"
                                        placeholder="Enter artist name"
                                    />
                                    <ErrorMessage
                                        name="artist"
                                        component="div"
                                        className="text-danger"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Genre</Form.Label>
                                    <Field
                                        id="category"
                                        as="select"
                                        name="genre"
                                        className="form-select"
                                        defaultValue=""
                                    >
                                        <option value="" disabled>
                                            Select Genre
                                        </option>
                                        {Object.entries(genreOptions).map(([key, value]) => (
                                            <option key={value} value={value}>
                                                {key}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage
                                        name="genre"
                                        component="div"
                                        className="text-danger"
                                    />
                                </Form.Group>

                                {/* <Form.Group className="mb-3">
                                    <Form.Label>Duration </Form.Label>
                                    <Field
                                        type="text"
                                        name="duration"
                                        className="form-control"
                                        placeholder="Enter duration in MM:SS format"
                                    />
                                    <ErrorMessage
                                        name="duration"
                                        component="div"
                                        className="text-danger"
                                    />
                                </Form.Group> */}

                                {/* <Form.Group className="mb-3">
                                    <Form.Label>Duration </Form.Label>
                                    <Field name="duration">
                                        {({ field, form }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter duration in MM:SS format"
                                                maxLength={5}
                                                onChange={(e) => {
                                                    let value = e.target.value;

                                                    if (/^\d{0,2}$/.test(value) || /^\d{0,2}:\d{0,2}$/.test(value)) {
                                                        form.setFieldValue("duration", value);
                                                    }

                                                    if (value.length > 2 && !value.includes(":")) {
                                                        value = `${value.slice(0, 2)}:${value.slice(2)}`;
                                                        form.setFieldValue("duration", value);
                                                    }
                                                }}
                                            />
                                        )}
                                    </Field>

                                    <ErrorMessage
                                        name="duration"
                                        component="div"
                                        className="text-danger"
                                    />
                                </Form.Group> */}


                                {/* <div className="recommended-w-toggle d-flex align-items-center">
                                    <p className="mb-0 me-3">Recommended</p>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        checked={values.recomended}
                                        onChange={(e) =>
                                            setFieldValue("recomended", e.target.checked)
                                        }
                                    />
                                </div> */}
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="border-none justify-content-center">
                            <button
                                type="button"
                                className="secondary"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                            <button type="submit" className="primary">
                                {selectedSong?._id ? "Update" : "Add"}
                            </button>
                        </Modal.Footer>
                    </FormikForm>
                )}
            </Formik>
        </Modal>
    );
};

export default CreateSongsModal;
