import React, { useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { startstopLoading } from "../Redux/Reducers/globalSlice";
import Dropdown from "react-bootstrap/Dropdown";
import {
  createClient,
  deleteClient,
  getClientDetailById,
  updateClient,
} from "../Redux/Actions/auth";
import { capitalize, formattedDate } from "../Utils/commonFiles";
import { Decryptedid, Encryptedid } from "../Utils/bcrypt";
import NoData from "../Components/NoData";
import CreateEventModal from "../Components/CommanModals/CreateEventModal";
import { deleteEvent, getClientsEvents, resetClientPassword } from "../Redux/Actions/event";
import PaginationComponent from "../Components/Pagination";
import CreateClientModal from "../Components/CommanModals/CreateClientModal";
import { toast } from "react-toastify";
import DeleteModal from "../Components/CommanModals/DeleteModal";
import { Modal } from "react-bootstrap";
import { Formik, Field, ErrorMessage, Form as FormikForm } from "formik";
import Form from "react-bootstrap/Form";
import * as Yup from "yup";

export default function ClientsDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const Id = id && Decryptedid(atob(id));

  const dropdownRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalClient, setShowModalClient] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const [showClientDeleteModal, setClientDeleteModal] = useState(null);


  const userInfo = useSelector((e) => e.userAuth.userDetail);
  const eventList = useSelector((e) => e.eventAuth.clientEventsList);

  const [openDropdownId, setOpenDropdownId] = useState(null);
  const dropdownRefs = useRef({});

  const totalEntries = eventList?.total ?? 0;
  const entriesPerPage = 5;

  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  const initialValues = {
    password: "",
    confirm_password: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .max(16, "Password must not exceed 16 characters")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must contain at least one special character"),

    confirm_password: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Confirm Password must match the Password"),
  });

  const handleSubmit = async (values) => {
    let data = {
      id: Id,
      newPassword: values.password
    }
    // try {
    //   await dispatch(resetClientPassword(data))
    // } catch (error) {
    //   toast.error(error);
    // }
  };

  const handleOpen = () => setShowModal(true);

  const handleClose = () => {
    setShowModal(false);
    setShow(false)
    setSelectedEvent(null);
  };

  const handleEventDetail = (id) => {
    navigate(`/events-details/${Encryptedid(id)}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    dispatch(getClientsEvents({ page: page, limit: 5, clientId: Id }));
  };

  const handleEventUpdate = (data) => {
    setSelectedEvent(data);
    handleOpen();
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const toggleDropdownEvent = (id) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const handleClickOutside1 = (event) => {
    if (
      !Object.values(dropdownRefs.current).some(
        (ref) => ref && ref.contains(event.target)
      )
    ) {
      setOpenDropdownId(null);
    }
  };

  const handleViewModalShow = (client) => {
    setSelectedClient(client);
    handleShowClient();
  };

  const handleCloseClient = () => {
    setShowModalClient(false);
    setSelectedClient(null);
  };

  const handleShowClient = () => {
    setShowModalClient(true);
  };

  const handleCreateClient = (clientData) => {
    dispatch(startstopLoading(true));

    const isUpdate = !!selectedClient?._id;
    const action = isUpdate ? updateClient : createClient;

    dispatch(action(clientData))
      .then((res) => {
        dispatch(startstopLoading(false));

        if (res?.payload?.statusCode === 200) {
          const message = isUpdate
            ? "Client Updated Successfully"
            : "Client Created Successfully";
          toast.success(message);
          // dispatch(getClients({ page: currentPage, limit: 10 }));
          dispatch(getClientDetailById({ id: Id }));
          handleCloseClient();
        } else {
          toast.error(res?.payload?.message || "Failed to process the request");
        }
      })
      .catch((error) => {
        console.error("Error in handleCreateClient:", error);
        toast.error("An error occurred. Please try again.");
      });
  };

  const handleOpenClientDeleteModal = () => {
    setClientDeleteModal(true);
  };

  const handleDeleteClient = () => {
    dispatch(deleteClient({ id: Id })).then((res) => {
      if (res?.payload?.statusCode == 200) {
        toast.success("Deleted Successfully");
        navigate(`/clients?page=0`);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const handleCloseClientDeleteModal = () => setClientDeleteModal(false);

  const handleOpenDeleteModal = (id) => {
    setEventToDelete(id);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleDeleteEvent = () => {
    dispatch(deleteEvent({ id: eventToDelete })).then((res) => {
      if (res?.payload?.statusCode == 200) {
        toast.success("Deleted Successfully");
        dispatch(getClientsEvents({ page: 0, limit: 5, clientId: Id }));
      } else {
        toast.error(res?.payload?.message);
      }
      handleCloseDeleteModal();
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside1);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside1);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    dispatch(startstopLoading(true));
    dispatch(getClientsEvents({ page: 0, limit: 5, clientId: Id }));
    dispatch(getClientDetailById({ id: Id })).then((res) => {
      dispatch(startstopLoading(false));
    });
  }, [dispatch]);

  return (
    <Layout>
      <Container fluid>
        <Row className="pb-5">
          <Col lg={12}>
            <div className="detail-view-card-client mt-3">
              <div className="detail-pro-img">
                <img src={require("../../src/Assets/Images/user-placeholder.png")} />
              </div>
              <div className="client-detail-w-txt">
                <div>
                  <div className="detail-pro-name">
                    <h2>{capitalize(userInfo?.fullName)}</h2>
                    <p>{userInfo?.email}</p>
                  </div>
                  <div className="detail-pro-edition">
                    <p>
                      Created Date:{" "}
                      <span>{formattedDate(userInfo?.createdAt)}</span>
                    </p>
                    <p>
                      Expected Booking Date:{" "}
                      <span>{formattedDate(userInfo?.eventDate)}</span>
                    </p>
                    <p>
                      Booked: <span>{eventList?.total ? 'Yes' : "No"}</span>
                    </p>
                  </div>
                </div>
                <div className="detail-pro-name event-details-text">
                  <p>
                    <span>
                      {userInfo?.notes ? capitalize(userInfo?.notes) : ""}
                    </span>
                  </p>
                </div>
              </div>

              <div className="detail-pro-name">
                <div>
                  {" "}
                  <button className="white-bg" onClick={handleShow}>Reset Password</button>
                  <button className="color-bg" onClick={handleOpen}>
                    Create Event
                  </button>
                </div>
              </div>
              <Dropdown className="dots-dropdown">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <div className="drop-pro-view">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      style={{ cursor: "pointer" }}
                    >
                      <g opacity="0.8">
                        <path
                          d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                          stroke="#132027"
                          strokeWidth="2"
                          strokeLinecap="square"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                          stroke="#132027"
                          strokeWidth="2"
                          strokeLinecap="square"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                          stroke="#132027"
                          strokeWidth="2"
                          strokeLinecap="square"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="my-profile"
                    onClick={() => handleViewModalShow(userInfo)}
                  >
                    <Dropdown.Item
                    >Edit</Dropdown.Item>
                  </div>

                  <div className="my-profile"
                    onClick={() => handleOpenClientDeleteModal()}
                  >
                    <Dropdown.Item> Delete</Dropdown.Item>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              {/* <div className="detail-pro-name" ref={dropdownRef}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  onClick={toggleDropdown}
                  style={{ cursor: "pointer" }}
                >
                  <g opacity="0.8">
                    <path
                      d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                      stroke="#132027"
                      strokeWidth="2"
                      strokeLinecap="square"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                      stroke="#132027"
                      strokeWidth="2"
                      strokeLinecap="square"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                      stroke="#132027"
                      strokeWidth="2"
                      strokeLinecap="square"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
                {isOpen && (
                  <div className="dropdown-menu flex-column gap-2">
                    <div
                      className="dropdown-item"
                      onClick={() => handleViewModalShow(userInfo)}
                      style={{ cursor: "pointer" }}
                    >
                      Edit
                    </div>
                    <div
                      className="dropdown-item"
                      // onClick={() => handleDeleteClient(userInfo?._id)}
                      onClick={() => handleOpenClientDeleteModal()}
                      style={{ cursor: "pointer" }}
                    >
                      Delete
                    </div>
                  </div>
                )}
              </div> */}
            </div>
          </Col>
          <Col lg={8}>
            <div className="comn-table-title">
              <h3>
                Bookings <span>({eventList?.total})</span>
              </h3>
            </div>
            <div className="filters">
              <div className="inner-filter-field">
                <div class="table-responsive">
                  <Table size="sm" className="table-cmn">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>
                          <div className="user-checkbox">
                            {/* <div className="remember-check">
                              <input
                                type="checkbox"
                                class="red"
                                id="filled-in-box"
                              />
                            </div> */}
                            Date Of Booking
                          </div>
                        </th>
                        <th>Status/ Action</th>
                        {/* <th>High Level Notes</th> */}
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {eventList?.event?.length > 0 &&
                        eventList?.event?.map((item, i) => (
                          <tr key={i}>
                            {/* <div className="first-user"> */}
                            <td>{i + 1}</td>
                            {/* <div className="remember-check">
                                <input
                                  type="checkbox"
                                  class="red"
                                  id="filled-in-box"
                                />
                              </div> */}
                            <td className="">
                              {formattedDate(item?.eventDate)}
                            </td>
                            <td>Booked</td>
                            {/* <td>
                            <div className="td-txt-bg" onClick={() => handleEventUpdate(item)} style={{ cursor: 'pointer' }}>View</div>
                          </td> */}
                            <td>
                              <div
                                className="td-txt-bg"
                                onClick={() => handleEventDetail(item?._id)}
                                style={{ cursor: "pointer" }}
                              >
                                View all details
                              </div>
                            </td>
                            <td>
                              <Dropdown className="dots-dropdown">
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                  <div className="drop-pro-view">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      onClick={() =>
                                        toggleDropdownEvent(item?._id)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <g opacity="0.8">
                                        <path
                                          d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                                          stroke="#132027"
                                          strokeWidth="2"
                                          strokeLinecap="square"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                                          stroke="#132027"
                                          strokeWidth="2"
                                          strokeLinecap="square"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                                          stroke="#132027"
                                          strokeWidth="2"
                                          strokeLinecap="square"
                                          strokeLinejoin="round"
                                        />
                                      </g>
                                    </svg>
                                  </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <div className="my-profile">
                                    <Dropdown.Item>Edit</Dropdown.Item>
                                  </div>

                                  {/* <Dropdown.Divider /> */}
                                  <div className="my-profile">
                                    <Dropdown.Item> Delete</Dropdown.Item>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                              {/* <div
                                className="detail-pro-name"
                                ref={(ref) =>
                                  (dropdownRefs.current[item._id] = ref)
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  onClick={() => toggleDropdownEvent(item?._id)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <g opacity="0.8">
                                    <path
                                      d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                                      stroke="#132027"
                                      strokeWidth="2"
                                      strokeLinecap="square"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                                      stroke="#132027"
                                      strokeWidth="2"
                                      strokeLinecap="square"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                                      stroke="#132027"
                                      strokeWidth="2"
                                      strokeLinecap="square"
                                      strokeLinejoin="round"
                                    />
                                  </g>
                                </svg>
                                {openDropdownId === item?._id && (
                                  <div className="dropdown-menu flex-column gap-2">
                                    <div
                                      className="dropdown-item"
                                      onClick={() => handleEventUpdate(item)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      Edit
                                    </div>
                                    <div
                                      className="dropdown-item"
                                      onClick={() =>
                                        handleOpenDeleteModal(item?._id)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      Delete
                                    </div>
                                  </div>
                                )}
                              </div> */}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  {eventList?.total > 1 && (
                    <PaginationComponent
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                      totalEntries={totalEntries}
                      entriesPerPage={entriesPerPage}
                    />
                  )}
                </div>
                {eventList?.event?.length == 0 && (
                  <div className="no-custom-data">
                    {" "}
                    <NoData />
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="comn-table-title">
              <h3>Chat</h3>
              {/* <button onClick={handleShow}>Create Client</button> */}
            </div>
            <div className="message-box-chatscreen">
              <div className="chatscreen-top justify-content-center">
              </div>
              <div className="chatscreen-middle">
                <NoData />
              </div>
              <div className="chatscreen-bottom">
                <input type="text" placeholder="Write message here..." />
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_103_5849)">
                      <path
                        d="M0.143157 3.08995C-0.0154793 2.65963 -0.0411072 2.19157 0.0696001 1.74652C0.180307 1.30146 0.422238 0.899952 0.763991 0.594112C1.10298 0.287237 1.52615 0.0889965 1.97891 0.0249753C2.43166 -0.0390459 2.89321 0.0340903 3.30399 0.234945L18.379 7.27161C18.7697 7.45195 19.1167 7.71457 19.3965 8.0415C19.6762 8.36842 19.882 8.75193 19.9998 9.16578H3.37316L0.190657 3.19578C0.173134 3.16128 0.157283 3.12597 0.143157 3.08995ZM3.38482 10.8333L0.257324 16.8124C0.239945 16.8452 0.224908 16.8792 0.212324 16.9141C0.0543303 17.3445 0.0293644 17.8125 0.140667 18.2572C0.25197 18.702 0.494401 19.103 0.836491 19.4083C1.25758 19.7875 1.80399 19.9976 2.37066 19.9983C2.71149 19.9983 3.05316 19.9216 3.37149 19.7658L18.3807 12.7341C18.772 12.5532 19.1195 12.2898 19.3993 11.9618C19.6791 11.6338 19.8846 11.2491 20.0015 10.8341H3.38482V10.8333Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_103_5849">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* create event modal */}
      <CreateEventModal
        id={Id}
        show={showModal}
        handleClose={handleClose}
        selectedEvent={selectedEvent}
      />

      {/* create client modal  */}
      <CreateClientModal
        show={showModalClient}
        handleClose={handleCloseClient}
        handleCreateClient={handleCreateClient}
        selectedClient={selectedClient}
      />
      {/* event delete modal */}
      <DeleteModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDeleteEvent}
        name={"Event"}
      />

      {/* client delete modal  */}
      <DeleteModal
        show={showClientDeleteModal}
        handleClose={handleCloseClientDeleteModal}
        handleDelete={handleDeleteClient}
        name={"Client"}
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ handleSubmit, errors, isValid }) => (
                <FormikForm onSubmit={handleSubmit}>
                  <div className="modal-cmn-form-set">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>New Password</Form.Label>
                      <Field
                        name="password"
                        type="password"
                        className="form-control"
                        placeholder="Enter password here"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Confirm Password</Form.Label>
                      <Field
                        name="confirm_password"
                        type="password"
                        className="form-control"
                        placeholder="Enter password here"
                      />
                      <ErrorMessage
                        name="confirm_password"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </div>
                  <Modal.Footer className="border-none justify-content-center">
                    <button className="secondary" onClick={handleClose} type="button" >
                      Cancel
                    </button>
                    <button className="primary"
                      type="submit"
                    >
                      Submit
                    </button>
                  </Modal.Footer>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Modal.Body>

      </Modal>
    </Layout>
  );
}
