import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../service/Api";

export const createEvent = createAsyncThunk("createEvent", async (data) => {
    const response = await API.post('event/create', data);
    return response.data;
});

export const getEvents = createAsyncThunk("getEvents", async ({ page, limit, search, startOfMonth, endOfMonth }) => {
    const response = await API.get('event/list', {
        params: { page, limit, search, startOfMonth, endOfMonth }
    });
    return response.data;
});

export const getEventDetail = createAsyncThunk("getEventDetail", async ({ id }) => {
    const response = await API.get('event/details', {
        params: { id }
    });
    return response.data;
});
export const getEventReqSongs = createAsyncThunk("getEventReqSongs", async ({ limit, page, clientId }) => {
    const response = await API.get('event/requested-song', {
        params: { limit, page, clientId }
    });
    return response.data;
});

export const getClientsEvents = createAsyncThunk("getClientsEvents", async ({ page, limit, search, clientId }) => {
    const response = await API.get('client/events', {
        params: { page, limit, search, clientId }
    });
    return response.data;
});

export const editEvent = createAsyncThunk("editEvent", async (data) => {
    const response = await API.put('event/edit', data);
    return response.data;
});
export const resetClientPassword = createAsyncThunk("resetClientPassword", async (data) => {
    const response = await API.put('auth/reset', data);
    return response.data;
});

export const deleteEvent = createAsyncThunk("deleteEvent", async ({ id }) => {
    const response = await API.delete('event/delete', {
        params: { id }
    });
    return response.data;
});


// request users for new user
export const getNewUsers = createAsyncThunk("getNewUsers", async ({ page, limit, search }) => {
    const response = await API.get('event/request-form', {
        params: { page, limit, search }
    });
    return response.data;
});
export const getMusician = createAsyncThunk("getMusician", async () => {
    const response = await API.get('event/musician');
    return response.data;
});
export const updateSongRequest = createAsyncThunk("updateSongRequest", async (data) => {
    const response = await API.post('event/approve-song', data);
    return response.data;
});