import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../service/Api";


export const createClient = createAsyncThunk("createClient", async (data) => {
    const response = await API.post('client/create', data);
    return response.data;
});
export const uploadFile = createAsyncThunk("uploadFile", async (data) => {
    const response = await API.post('auth/upload', data);
    return response.data;
});

export const getClients = createAsyncThunk("getClients", async ({ page, limit, search }) => {
    const response = await API.get('client/list', {
        params: { page, limit, search }
    });
    return response.data;
});

export const getAllClients = createAsyncThunk("getAllClients", async () => {
    const response = await API.get('client/all');
    return response.data;
});


export const sendForgetPasswordMail = createAsyncThunk("sendForgetPasswordMail", async (data) => {
    const response = await API.post('auth/forgot', data);
    return response.data;
});

export const resetPassword = createAsyncThunk("resetPassword", async (data) => {
    const response = await API.put('auth/reset', data);
    return response.data;
});


export const updateClient = createAsyncThunk("updateClient", async (data) => {
    const response = await API.put('client/edit', data);
    return response.data;
});

export const getAdminDetails = createAsyncThunk("getAdminDetails", async () => {
    const response = await API.get('profile/info');
    return response.data;
});

export const logoutAction = createAsyncThunk("logoutAction", async (data) => {
    const response = await API.post('auth/logout', data);
    return response.data;
});

export const getClientDetailById = createAsyncThunk("getClientDetailById", async ({ id }) => {
    const response = await API.get('client/details', {
        params: { id }
    });
    return response.data;
});


export const deleteClient = createAsyncThunk("deleteClient", async ({ id }) => {
    const response = await API.delete('client/delete', {
        params: { id }
    });
    return response.data;
});

export const deleteNewUsers = createAsyncThunk("deleteNewUsers", async (data) => {
    const response = await API.post('event/request-delete', data);
    return response.data;
});