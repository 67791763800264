import { createSlice } from "@reduxjs/toolkit";
import { presetsAllAction, presetsListAction } from "../Actions/presets";

const initialState = {
    loading: false,
    presetList: [],
    presetAllList: []
}

const PresetSlice = createSlice({
    name: "preset",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(presetsListAction.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.success = true;
                state.presetList = payload.data;
            } else {
                state.success = false;
                state.error = payload?.message
            }
        })

        // ========================================================================
        builder.addCase(presetsAllAction.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.success = true;
                state.presetAllList = payload.data;
            } else {
                state.success = false;
                state.error = payload?.message
            }
        })

    },
});

export default PresetSlice.reducer;