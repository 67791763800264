import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./Reducers/authSlice";
import globalReducer from "./Reducers/globalSlice"
import eventReducer from "./Reducers/eventSlice"
import songReducer from "./Reducers/songSlice"
import presetReducer from "./Reducers/presetSlice"
import adminReducer from "./Reducers/adminActionSlice"

export const store = configureStore({
  reducer: {
    globalAuth: globalReducer,
    userAuth: userReducer,
    eventAuth: eventReducer,
    songAuth: songReducer,
    presetAuth: presetReducer,
    adminAuth: adminReducer
  },
});
