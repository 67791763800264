import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import API from "../../service/Api";

export const AdminLogin = (adminData, keepLogin) => async (dispatch) => {
    const cookies = new Cookies();

    try {
        const response = await API.post("auth/login", adminData);
        const { status, data } = response;

        const token = data?.data?.token;
        const fullPermission = data?.data?.fullPermission;
        const readOnlyPermission = data?.data?.readOnlyPermission;

        if (status === 200 && token) {
            sessionStorage.setItem("adminToken", token);
            sessionStorage.setItem("fullPermission", fullPermission);
            sessionStorage.setItem("readOnlyPermission", readOnlyPermission);

            if (keepLogin) {
                cookies.set("adminToken", token, {
                    path: "/",
                    expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7), // Set token to expire in 7 days
                });
            }

            window.location.href = "/clients";
        } else {
            toast.error(data?.message || "Login failed.");
        }
    } catch (error) {
        toast.warning(error.response?.data?.message || error.message || "An error occurred during login.");
    }
};
