import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Field, Formik, FieldArray, ErrorMessage, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { createPresets, presetsListAction } from '../../Redux/Actions/presets';
import { toast } from 'react-toastify';


const AddPresetModal = ({ show, handleClose }) => {
    const dispatch = useDispatch()
    const initialValues = {
        eventType: '',
        sections: [{ sectionName: '', totalSongs: 1 }],
    };

    const validationSchema = Yup.object({
        eventType: Yup.string().required('Event type is required'),
        sections: Yup.array()
            .of(
                Yup.object({
                    sectionName: Yup.string().required('Section name is required'),
                    totalSongs: Yup.number().min(1, 'Songs must be at least 1').required('Songs count is required'),
                })
            )
            .min(1, 'At least one section is required'),
    });

    const handleSubmit = (values) => {

        dispatch(createPresets(values)).then((res) => {
            if (res?.payload?.statusCode == 200) {
                toast.success("Preset Created Successfully");
                dispatch(presetsListAction({ page: 0, limit: 10 }))
                handleClose();
            } else {
                toast.error(res?.payload?.message);
            }
        })
    };

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header className="border-none justify-content-center">
                <Modal.Title className="mt-3">Add Preset</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, handleSubmit }) => (
                    <FormikForm onSubmit={handleSubmit}>
                        <Modal.Body className="py-0">
                            <Form.Group className="mb-3">
                                <Form.Label>Event Type</Form.Label>
                                <Field
                                    type="text"
                                    name="eventType"
                                    className="form-control"
                                    placeholder="Enter Event Type"
                                    onChange={handleChange}
                                />
                                <ErrorMessage
                                    name="eventType"
                                    component="div"
                                    className="text-danger"
                                />
                            </Form.Group>

                            <FieldArray
                                name="sections"
                                render={(arrayHelpers) => (
                                    <>
                                        {values.sections.map((section, index) => (
                                            <div key={index} className="modal-cmn-form-set">
                                                <Form.Group className="mb-3">
                                                    <div className="sections-add-title">
                                                        <Form.Label>Section Name</Form.Label>
                                                        <Form.Label>Songs</Form.Label>
                                                    </div>

                                                    <div className="sections-add-plus">
                                                        <Field
                                                            name={`sections[${index}].sectionName`}
                                                            placeholder={`Section ${index + 1} Name`}
                                                            className="form-control"
                                                        />
                                                        <div className="sections-song">
                                                            <Field
                                                                name={`sections[${index}].totalSongs`}
                                                                type="number"
                                                                min="1"
                                                                className="form-control"
                                                            />
                                                        </div>
                                                        {index > 0 && (
                                                            <svg
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="25"
                                                                height="24"
                                                                viewBox="0 0 25 24"
                                                                fill="none"
                                                            >
                                                                <circle cx="12.7141" cy="12" r="9" stroke="#CCD2E3" strokeWidth="2" />
                                                                <path d="M8.21411 12H17.2141" stroke="#CCD2E3" strokeWidth="2" />
                                                            </svg>
                                                        )}
                                                    </div>

                                                    <ErrorMessage
                                                        name={`sections[${index}].sectionName`}
                                                        component="div"
                                                        className="text-danger"

                                                    />
                                                    <ErrorMessage
                                                        name={`sections[${index}].totalSongs`}
                                                        component="div"
                                                        className="text-danger"

                                                    />
                                                </Form.Group>
                                            </div>
                                        ))}

                                        <div className="sections-add-btn">
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.push({ sectionName: '', totalSongs: 1 })}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM13 13V17H11V13H7V11H11V7H13V11H17V13H13Z"
                                                        fill="#979699"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </>
                                )}
                            />
                        </Modal.Body>
                        <Modal.Footer className="border-none justify-content-center">
                            <button className="secondary" type="button" onClick={handleClose}>
                                Close
                            </button>
                            <button className="primary" type="submit">
                                Add
                            </button>
                        </Modal.Footer>
                    </FormikForm>
                )}
            </Formik>
        </Modal>
    );
};

export default AddPresetModal;
