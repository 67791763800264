import React from "react";
import Pagination from "react-bootstrap/Pagination";

const PaginationComponent = ({ currentPage, totalPages, onPageChange, totalEntries, entriesPerPage }) => {

    const adjustedCurrentPage = currentPage + 1;
    const totalPageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

    const handlePageClick = (page) => {
        if (page !== adjustedCurrentPage) {
            onPageChange(page - 1);
        }
    };

    const startEntry = (adjustedCurrentPage - 1) * entriesPerPage + 1;
    const endEntry = Math.min(adjustedCurrentPage * entriesPerPage, totalEntries);

    return (
        <div className="pagination-section">
            <div className="showing-user">
                <p>
                    Showing {startEntry} to {endEntry} of {totalEntries} entries
                </p>
            </div>
            <div className="pagination-block">
                <Pagination>
                    <Pagination.First
                        onClick={() => handlePageClick(1)}
                        disabled={adjustedCurrentPage === 1}
                    />
                    <Pagination.Prev
                        onClick={() => handlePageClick(adjustedCurrentPage - 1)}
                        disabled={adjustedCurrentPage === 1}
                    />
                    {totalPageNumbers.map((page) => (
                        <Pagination.Item
                            key={page}
                            active={page === adjustedCurrentPage}
                            onClick={() => handlePageClick(page)}
                        >
                            {page}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next
                        onClick={() => handlePageClick(adjustedCurrentPage + 1)}
                        disabled={adjustedCurrentPage === totalPages}
                    />
                    <Pagination.Last
                        onClick={() => handlePageClick(totalPages)}
                        disabled={adjustedCurrentPage === totalPages}
                    />
                </Pagination>
            </div>
        </div>
    );
};

export default PaginationComponent;
