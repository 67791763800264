import React, { useEffect, useState } from 'react'
import Layout from '../Components/Layout/Layout'
import { Container, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { startstopLoading } from '../Redux/Reducers/globalSlice'
import { getNewUsers } from '../Redux/Actions/event'
import NoData from '../Components/NoData'
import { capitalize, convertTo12HourFormat, formattedDate, formatYearAndMonth } from '../Utils/commonFiles'
import PaginationComponent from '../Components/Pagination'
import { deleteNewUsers } from '../Redux/Actions/auth'
import { toast } from 'react-toastify'
import DeleteModal from '../Components/CommanModals/DeleteModal'

const Users = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedIds, setSelectedIds] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const newUsersLists = useSelector((e) => e.eventAuth.newUserLists)

    const totalEntries = newUsersLists?.total ?? 0;
    const entriesPerPage = 10;
    const totalPages = Math.ceil(totalEntries / entriesPerPage);

    useEffect(() => {
        if (location.pathname === "/users") {
            const params = new URLSearchParams(location.search);
            const page = params.get("page") || 0;
            console.log(params, "params")
            setCurrentPage(page)
            const searchQuery = params.get("search") || "";
            const queryParams = searchQuery ? { search: searchQuery } : { page: page, limit: 10 };
            dispatch(startstopLoading(true));
            dispatch(getNewUsers(queryParams)).then((res) => {
                dispatch(startstopLoading(false));
            });
        }
    }, [location.search, dispatch, location.pathname]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        navigate(`/users?page=${page}`)
    };

    const handleCheckboxChange = (id) => {
        setSelectedIds((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((selectedId) => selectedId !== id)
                : [...prevSelected, id]
        );
    };

    const handleDelete = () => {
        dispatch(deleteNewUsers({ ids: selectedIds })).then((res) => {
            if (res?.payload?.statusCode === 200) {
                // const params = new URLSearchParams(location.search);
                // const page = params.get("page") || 0;
                // dispatch(getNewUsers({page : page, limit: 10}))

                const currentPage = location.search ? new URLSearchParams(location.search).get('page') : 0;
                dispatch(getNewUsers({ page: currentPage, limit: 10 }))
                toast.success("Deleted Successfully");
            } else {
                toast.error(res?.payload?.message);
            }
        });
        setShowDeleteModal(false);
        setSelectedIds([]);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    const handleDeleteButtonClick = () => {
        setShowDeleteModal(true);
    };

    return (
        <Layout>
            <Container fluid>
                <div className="comn-table-title">
                    <h3>
                        New Users <span>({newUsersLists?.total ?? "0"})</span>
                    </h3>
                </div>
                <div className="filters">
                    <div className="select-td-delete">
                        {selectedIds.length > 0 && (
                            <button onClick={handleDeleteButtonClick} className="delete-button">
                                Delete
                            </button>
                        )}
                    </div>
                    <div className="inner-filter-field">
                        <div class="table-responsive">
                            <Table size="sm" className="table-cmn custom-th-colm">
                                <thead>
                                    <tr>
                                        {/* <th className='m-5'>S.No</th> */}
                                        <th>Name </th>
                                        <th>Email</th>
                                        <th>Created Date</th>
                                        <th>Wedding Date </th>
                                        <th>Wedding Month, Year</th>
                                        <th>Wedding Venue</th>
                                        <th>Ceremony Time (if known)</th>
                                        <th>Met At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {newUsersLists?.form?.length > 0 ? newUsersLists?.form?.map((item, i) => (
                                        <tr key={i}>
                                            {/* <td className='m-5'>{(currentPage) * 10 + i + 1}</td> */}
                                            <td>
                                                <div className="first-user">
                                                    <div className="remember-check">
                                                        <input
                                                            type="checkbox"
                                                            className="red"
                                                            id={`checkbox-${item?._id}`}
                                                            onChange={() => handleCheckboxChange(item._id)}
                                                            checked={selectedIds.includes(item._id)}
                                                        />
                                                    </div>
                                                    <div className="user-profile" style={{ cursor: 'pointer' }} >{capitalize(item?.fullName)}</div>
                                                </div>
                                            </td>
                                            {/* <td>
                                                <div className="user-profile" style={{ cursor: 'pointer' }} >{capitalize(item?.fullName)}</div>
                                            </td> */}
                                            <td>{item?.email}</td>
                                            <td>{formattedDate(item?.createdAt)}</td>
                                            <td>{item?.weddingDate ? formattedDate(item?.weddingDate) : "-"}</td>
                                            <td>{formatYearAndMonth(item?.yearAndMonth)}</td>
                                            <td>{capitalize(item?.weddingVenue)}</td>
                                            <td>{convertTo12HourFormat(item?.ceremonyTime)}</td>
                                            <td>{item?.meetVenue ? item?.meetVenue : "-"}</td>
                                        </tr>
                                    )) : <NoData />}
                                </tbody>
                            </Table>
                            {newUsersLists?.total > 1 && (
                                <PaginationComponent
                                    currentPage={parseInt(new URLSearchParams(location.search).get("page") || "0")}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                    totalEntries={totalEntries}
                                    entriesPerPage={entriesPerPage}
                                />)}
                        </div>
                    </div>
                </div>

                <DeleteModal
                    show={showDeleteModal}
                    handleClose={handleCloseDeleteModal}
                    handleDelete={handleDelete}
                    name={"Users"}
                />

            </Container>
        </Layout>
    )
}

export default Users
