import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../service/Api";

const AdminActions = {
    createAdministrator: createAsyncThunk("createAdministrator", async (data) => {
        const response = await API.post('sub/create', data);
        return response.data;
    }),
    editAdministrator: createAsyncThunk("editAdministrator", async (data) => {
        const response = await API.put('sub/edit', data);
        return response.data;
    }),
    deleteAdministrator: createAsyncThunk("deleteAdministrator", async ({ id }) => {
        const response = await API.delete('sub/delete', {
            params: { id }
        });
        return response.data;
    }),
    getAdministrator: createAsyncThunk("getAdministrator", async ({ page, limit, search }) => {
        const response = await API.get('sub/list', {
            params: { page, limit, search }
        });
        return response.data;
    }),

    createFaq: createAsyncThunk("createFaq", async (data) => {
        const response = await API.post('faq/create', data);
        return response.data;
    }),
    editFaq: createAsyncThunk("editFaq", async (data) => {
        const response = await API.put('faq/update', data);
        return response.data;
    }),
    getFaq: createAsyncThunk("getFaq", async ({ page, limit, search }) => {
        const response = await API.get('faq/list', {
            params: { page, limit, search }
        });
        return response.data;
    }),
    deleteFaq: createAsyncThunk("deleteFaq", async ({ id }) => {
        const response = await API.delete(`faq/delete`, {
            params: { id }
        });
        return response.data;
    }),
    createResource: createAsyncThunk("createResource", async (data) => {
        const response = await API.post('resource/create', data);
        return response.data;
    }),
    getResource: createAsyncThunk("getResource", async ({ page, limit, search }) => {
        const response = await API.get('resource/all', {
            params: { page, limit, search }
        });
        return response.data;
    }),
    deleteResource: createAsyncThunk("deleteResource", async ({ id }) => {
        const response = await API.delete(`resource/delete`, {
            params: { id }
        });
        return response.data;
    })

}

export default AdminActions