import React from 'react';
import { Modal } from 'react-bootstrap';

const NotesViewModal = ({ show, handleClose, notes }) => {
    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header className="border-none justify-content-center">
                <Modal.Title className="mt-3">View Notes</Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-0">
                <div className="mb-3">
                    <textarea
                        className="form-control"
                        rows={5}
                        value={notes}
                        readOnly
                    />
                </div>
            </Modal.Body>
            <Modal.Footer className="border-none justify-content-center">
                <button className="secondary" type="button" onClick={handleClose}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default NotesViewModal;
