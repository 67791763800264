import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../Pages/Login";
import ResetPassword from "../Pages/ResetPassword";
import Error from "../Pages/Error";
import SignUp from "../Pages/SignUp";
import Clients from "../Pages/Clients";
import ForgotPassword from "../Pages/ForgotPassword";
import ClientsDetail from "../Pages/ClientsDetail";
import Events from "../Pages/Events";
import EventsDetails from "../Pages/EventsDetails";
import Transactions from "../Pages/Transactions";
import Songs from "../Pages/Songs";
import Administrators from "../Pages/Administrators";
import Messages from "../Pages/Messages";
import Resources from "../Pages/Resources";
import Faqs from "../Pages/Faqs";
import Presets from "../Pages/Presets";
import PrivateRoute from "./PrivateRoute";
import Users from "../Pages/Users";

const RoutesPage = () => {
    return (
        <>
            <Router>
                <Routes>
                    {/* public routes */}
                    <Route path="/" element={<Login />}></Route>
                    <Route path="/sign-up" element={<SignUp />}></Route>
                    <Route path="/forgot-password" element={<ForgotPassword />}></Route>
                    <Route path="/reset-password/:id" element={<ResetPassword />}></Route>
                    <Route path="/error" element={<Error />}></Route>
                    {/* <Route path="/otp-varification" element={<OtpVarification />}></Route> */}

                    {/* private routes */}
                    <Route element={<PrivateRoute />}>
                        <Route path="/clients" element={<Clients />}></Route>
                        <Route path="/client-detail/:id" element={<ClientsDetail />}></Route>
                        <Route path="/events" element={<Events />}></Route>
                        <Route path="/events-details/:id" element={<EventsDetails />}></Route>
                        <Route path="/transactions" element={<Transactions />}></Route>
                        <Route path="/songs" element={<Songs />}></Route>
                        <Route path="/administrators" element={<Administrators />}></Route>
                        <Route path="/messages" element={<Messages />}></Route>
                        <Route path="/resources" element={<Resources />}></Route>
                        <Route path="/faqs" element={<Faqs />}></Route>
                        <Route path="/presets" element={<Presets />}></Route>
                        <Route path="/users" element={<Users />}></Route>
                    </Route>
                </Routes>
            </Router>
        </>
    )
}

export default RoutesPage