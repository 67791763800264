import { createSlice } from "@reduxjs/toolkit";
import { getClientsEvents, getEventDetail, getEventReqSongs, getEvents, getMusician, getNewUsers, updateSongRequest } from "../Actions/event";
import { toast } from "react-toastify";

const initialState = {
    loading: false,
    eventsList: [],
    eventDetail: [],
    requestedSongs: [],
    clientEventsList: [],
    visibleRange: null,
    newUserLists: [],
    MusiciansList: []
}

const EventSlice = createSlice({
    name: "event",
    initialState,
    reducers: {
        setVisibleRange(state, action) {
            state.visibleRange = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getEvents.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.success = true;
                state.eventsList = payload.data;
            } else {
                state.success = false;
                state.error = payload?.message
            }
        })

        // ========================================================================

        builder.addCase(getEventDetail.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.success = true;
                state.eventDetail = payload.data;
            } else {
                state.success = false;
                state.error = payload?.message
            }
        })
        // =========================================================================
        builder.addCase(getEventReqSongs.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.success = true;
                state.requestedSongs = payload.data;
            } else {
                state.success = false;
                state.error = payload?.message
            }
        })

        // =========================================================================

        builder.addCase(getClientsEvents.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.success = true;
                state.clientEventsList = payload.data;
            } else {
                state.success = false;
                state.error = payload?.message
            }
        })

        // =========================================================================

        builder.addCase(getNewUsers.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.success = true;
                state.newUserLists = payload.data;
            } else {
                state.success = false;
                state.error = payload?.message
            }
        })
        builder.addCase(updateSongRequest.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.loading = false
                toast.success(payload?.message)
            } else {
                toast.error(payload?.message)
                state.loading = false
            }
        })
        builder.addCase(updateSongRequest.rejected, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.loading = false
                toast.success(payload?.message)
            } else {
                toast.error(payload?.message)
                state.loading = false
            }
        })
        builder.addCase(getMusician.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.loading = false
                // toast.success(payload?.message)
                state.MusiciansList = payload.data
            } else {
                toast.error(payload?.message)
                state.loading = false
            }
        })
        builder.addCase(getMusician.rejected, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.loading = false
                // toast.success(payload?.message)
            } else {
                // toast.error(payload?.message)
                state.loading = false
            }
        })

    },
});

export const { setVisibleRange } = EventSlice.actions;
export default EventSlice.reducer;