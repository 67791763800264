import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AdminLogin } from "../Redux/Reducers/adminLogin";
import { startstopLoading } from "../Redux/Reducers/globalSlice";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

export default function Login() {

  const dispatch = useDispatch()

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = async (values, { setStatus }) => {
    setStatus([]);
    setLoading(true)
    dispatch(startstopLoading(true));

    try {
      await dispatch(AdminLogin(values));
      setLoading(false)
      dispatch(startstopLoading(false));

    } catch (error) {
      setStatus([error.message || "An error occurred during login."]);
    }
  };

  return (
    <div className="p-3">
      <Container fluid>
        <div className="row sign-banner-part">
          <Col lg={6} className="p-0">
            <div className="left-banner-img"></div>
          </Col>
          <Col lg={6}>
            <div className="right-banner-part">
              <div className="login-cmn-box">
                <div className="login-box-inner-wrap">
                  <div className="login-logo">
                    <img src={require("../Assets/Images/dark-logo.png")} alt="Logo" />
                  </div>
                  <h2>Welcome back!</h2>
                  <p>Enter your Email and Password to Log In</p>

                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    validate={(values) => {
                      const errors = {};
                      const statusErrors = [];
                      try {
                        validationSchema.validateSync(values, {
                          abortEarly: false,
                        });
                      } catch (validationError) {
                        validationError.inner.forEach((err) => {
                          statusErrors.push(err.message);
                        });
                      }
                      if (statusErrors.length > 0) {
                        errors.statusErrors = statusErrors;
                      }
                      return errors;
                    }}
                  >
                    {({ errors, touched, status, setStatus }) => (
                      <Form>
                        {((status && status.length > 0) ||
                          errors.statusErrors) && (
                            <div
                              className="global-error text-danger p-2 mb-3"
                              style={{
                                backgroundColor: "#f8d7da",
                                border: "1px solid #f5c6cb",
                                borderRadius: "5px",
                              }}
                            >
                              <ul className="mb-0 p-0 text-center ">
                                {errors?.statusErrors?.map((err, index) => (
                                  <li style={{ listStyle: "none" }} key={index} className="h6 mb-0">{err}</li>
                                ))}
                              </ul>
                            </div>
                          )}

                        <div className="form-set">
                          <div className="mb-3">
                            <label htmlFor="email">Email ID</label>
                            <Field
                              type="email"
                              name="email"
                              id="email"
                              className={`form-control`}
                              placeholder="Enter your email"
                            />
                            {/* <ErrorMessage
                              name="email"
                              component="div"
                            className="text-danger"
                            /> */}
                          </div>

                          <div className="mb-3 pass-eys">
                            <label htmlFor="password">Password</label>
                            <Field
                              type={showPassword ? "text" : "password"}
                              name="password"
                              id="password"
                              className={`form-control password-input`}
                              placeholder="Password"
                            />
                            <img
                              src={require("../Assets/Images/eye.svg").default}
                              alt="Show Password"
                              onClick={() => setShowPassword(!showPassword)}
                              style={{ cursor: "pointer" }}
                            />
                            {/* <ErrorMessage
                              name="password"
                              component="div"
                              className="text-danger"
                            /> */}
                          </div>
                        </div>

                        <div className="pass-rember-line">
                          <Link to="/forgot-password" className="forgot">
                            Forgot Password?
                          </Link>
                        </div>

                        <button type="submit" variant="primary" className="submit">
                          {isLoading ? "Loading..." : "Log In"}
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  );
}
