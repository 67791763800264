import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../service/Api";

export const createPresets = createAsyncThunk("createPresets", async (data) => {
    const response = await API.post('preset/create', data);
    return response.data;
});

export const presetsAllAction = createAsyncThunk("presetsAllAction", async () => {
    const response = await API.get('preset/all');
    return response.data;
});

export const presetsListAction = createAsyncThunk("presetsListAction", async ({ page, limit, search }) => {
    const response = await API.get('preset/list', {
        params: { page, limit, search }
    });
    return response.data;
});


export const deletePreset = createAsyncThunk("deletePreset", async ({ id }) => {
    const response = await API.delete('preset/delete', {
        params: { id }
    });
    return response.data;
});

