import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useState } from "react";
import CreateClientModal from "../Components/CommanModals/CreateClientModal";
import { useDispatch, useSelector } from "react-redux";
import { createClient, getClients, updateClient } from "../Redux/Actions/auth";
import { toast } from "react-toastify";
import { startstopLoading } from "../Redux/Reducers/globalSlice";
import NoData from "../Components/NoData";
import { capitalize, dateOfBookingEventWise, formattedDate } from "../Utils/commonFiles";
import PaginationComponent from "../Components/Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import { Encryptedid } from "../Utils/bcrypt";
import NotesViewModal from "../Components/CommanModals/NotesViewModal";

export default function Clients() {

  const dispatch = useDispatch()
  const location = useLocation();
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [showModalNotes, setShowModalNotes] = useState(false);
  const [selectedNotes, setSelectedNotes] = useState("");

  const getClientsList = useSelector((e) => e.userAuth.clientList)

  const handleClose = () => {
    setShowModal(false)
    setSelectedClient(null)
  }

  const handleShow = () => {
    setShowModal(true)
  }

  useEffect(() => {
    if (location.pathname === "/clients") {
      const params = new URLSearchParams(location.search);
      const page = params.get("page") || 0;
      setCurrentPage(page)
      const searchQuery = params.get("search") || "";
      const queryParams = searchQuery ? { search: searchQuery } : { page: page, limit: 10 };
      dispatch(startstopLoading(true));

      dispatch(getClients(queryParams)).then((res) => {
        dispatch(startstopLoading(false));
      });
    }
  }, [location.search, dispatch, location.pathname]);

  const handleCreateClient = (clientData) => {
    dispatch(startstopLoading(true));

    const isUpdate = !!selectedClient?._id;
    const action = isUpdate ? updateClient : createClient;

    dispatch(action(clientData)).then((res) => {
      dispatch(startstopLoading(false));

      if (res?.payload?.statusCode === 200) {
        const message = isUpdate
          ? "Client Updated Successfully"
          : "Client Created Successfully";
        toast.success(message);
        dispatch(getClients({ page: currentPage, limit: 10 }));
        handleClose();
      } else {
        toast.error(res?.payload?.message || "Failed to process the request");
      }
    })
      .catch((error) => {
        console.error("Error in handleCreateClient:", error);
        toast.error("An error occurred. Please try again.");
      });
  };


  const handleViewModalShow = (client) => {
    setSelectedClient(client);
    handleShow();
  };

  const totalEntries = getClientsList?.total ?? 0;
  const entriesPerPage = 10;

  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(`/clients?page=${page}`)
    // dispatch(getClients({ page: page, limit: 10 }))
  };

  const handleClientDetail = (data) => {
    navigate(`/client-detail/${Encryptedid(data?._id)}`)
  }

  const handleViewNotes = (notes) => {
    setSelectedNotes(notes);
    setShowModalNotes(true);
  };

  return (
    <Layout>
      <Container fluid>
        <div className="comn-table-title">
          <h3>
            Clients <span>({getClientsList?.total ?? "0"})</span>
          </h3>
          <button onClick={handleShow}>Create Client</button>
        </div>
        <div className="filters">
          <div className="inner-filter-field">
            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>
                      <div className="user-checkbox">
                        <div className="remember-check">
                          <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          />
                        </div>
                        Name
                      </div>
                    </th>
                    <th>Email</th>
                    <th>Created Date</th>
                    <th>Booked </th>
                    <th>Date Of Booking</th>
                    <th>High Level Notes</th>
                    {/* <th></th> */}
                  </tr>
                </thead>
                <tbody>
                  {getClientsList?.client?.length > 0 ? getClientsList?.client?.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <div className="first-user">
                          <div className="remember-check">
                            <input
                              type="checkbox"
                              class="red"
                              id="filled-in-box"
                            />
                          </div>
                          <div className="user-profile" style={{ cursor: 'pointer' }} onClick={() => handleClientDetail(item)}>{capitalize(item?.fullName)}</div>
                        </div>
                      </td>
                      <td>{item?.email}</td>
                      <td>{formattedDate(item?.createdAt)}</td>
                      <td>{item.eventDate?.length ? 'Yes' : "No"}</td>
                      <td>{dateOfBookingEventWise(item?.eventDate)}</td>
                      <td>
                        <div className="td-txt-bg"
                          onClick={() => handleViewNotes(item?.notes || "No notes available")}
                          style={{ cursor: "pointer" }}>View</div>
                      </td>
                    </tr>
                  )) : <NoData />}

                </tbody>
              </Table>
              {getClientsList?.total > 1 && (
                <PaginationComponent
                  currentPage={parseInt(new URLSearchParams(location.search).get("page") || "0")}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                  totalEntries={totalEntries}
                  entriesPerPage={entriesPerPage}
                />)}
            </div>
          </div>
        </div>
        <div className="mt-0">
          <p className="pb-4"></p>
        </div>
      </Container>

      {/* create client modal */}
      <CreateClientModal
        show={showModal}
        handleClose={handleClose}
        handleCreateClient={handleCreateClient}
        selectedClient={selectedClient}
      />
      {/* view notes modal  */}
      <NotesViewModal
        show={showModalNotes}
        handleClose={() => setShowModalNotes(false)}
        notes={selectedNotes}
      />

    </Layout>
  );
}
