import { createSlice } from "@reduxjs/toolkit";
import { getAdminDetails, getAllClients, getClientDetailById, getClients } from "../Actions/auth";

const initialState = {
    loading: false,
    clientList: [],
    userDetail: [],
    allClientList: []
}

const UserSlice = createSlice({
    name: "user",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getClients.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.success = true;
                state.clientList = payload.data;
            } else {
                state.success = false;
                state.error = payload?.message
            }
        })

        // ==========================================================================================

        builder.addCase(getAdminDetails.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.success = true;
                state.adminDetail = payload.data;
            } else {
                state.success = false;
                state.error = payload?.message
            }
        })
        // ==========================================================================================

        builder.addCase(getClientDetailById.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.success = true;
                state.userDetail = payload.data;
            } else {
                state.success = false;
                state.error = payload?.message
            }
        })


          // ==========================================================================================

          builder.addCase(getAllClients.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.success = true;
                state.allClientList = payload.data;
            } else {
                state.success = false;
                state.error = payload?.message
            }
        })
    },
});

export default UserSlice.reducer;