import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../service/Api";

export const createSongs = createAsyncThunk("createSongs", async (data) => {
    const response = await API.post('song/create', data);
    return response.data;
});

export const getSongs = createAsyncThunk("getSongs", async ({ page, limit, search }) => {
    const response = await API.get('song/list', {
        params: { page, limit, search }
    });
    return response.data;
});

export const editSong = createAsyncThunk("editSong", async (data) => {
    const response = await API.put('song/edit', data);
    return response.data;
});
export const deleteSong = createAsyncThunk("deleteSong", async ({ id }) => {
    const response = await API.delete('song/delete', {
        params: { id }
    });
    return response.data;
});
