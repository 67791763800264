export const JOIN_ROOM = "joinRoom";
export const ADMIN_EVENTS_LISTING_EVENT = "view_events";
export const ADMIN_GET_USER_CHAT_EVENT = "get_chat";
export const ADMIN_SEND_MESSAGE_EVENT = "send_message";

export const ADMIN_EVENTS_LISTING_LISTENER = "eventlist";
export const ADMIN_GET_USER_CHAT_LIST_LISTENER = "messages";
export const ADMIN_GET_USER_MESSAGE_LISTENER = "receive_message";
export const ADMIN_JOINED_USER_ROOM_LISTENER = "joinedRoomUser";


