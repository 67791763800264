import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import AddPresetModal from "../Components/CommanModals/AddPresetModal";
import { startstopLoading } from "../Redux/Reducers/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import { deletePreset, presetsListAction } from "../Redux/Actions/presets";
import { capitalize } from "../Utils/commonFiles";
import NoData from "../Components/NoData";
import { toast } from "react-toastify";
import DeleteModal from "../Components/CommanModals/DeleteModal";

export default function Presets() {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false); 
  const [presetToDelete, setPresetToDelete] = useState(null); 

  const presetDataList = useSelector((e) => e.presetAuth.presetList)

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    dispatch(startstopLoading(true));
    dispatch(presetsListAction({ page: 0, limit: 100 })).then((res) => {
      dispatch(startstopLoading(false));
    });
  }, [dispatch]);

  const handleOpenDeleteModal = (id) => {
    setPresetToDelete(id);
    setShowDeleteModal(true); 
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false); 

  const handleDeletePreset = () => {
    dispatch(deletePreset({ id: presetToDelete  })).then((res) => {
      if (res?.payload?.statusCode == 200) {
        dispatch(presetsListAction({ page: 0, limit: 100 }))
        toast.success("Deleted Successfully")
      } else {
        toast.error(res?.payload?.message)
      }
      handleCloseDeleteModal();
    })
  }

  return (
    <Layout>
      <Container fluid>
        <div className="comn-table-title">
          <h3>
            Presets <span>({presetDataList?.total})</span>
          </h3>
          <button onClick={handleOpenModal}>Create New</button>
        </div>
        <div className="songlist-selection mt-3">
          <div className="my-profile-title">
            <h4>Presets</h4>
          </div>
          <hr />
          <div className="faq-list-accro">
            <Accordion defaultActiveKey="0" flush>
              {presetDataList?.preset?.length > 0 ? presetDataList?.preset?.map((item, index) => (
                <Accordion.Item eventKey={index.toString()} key={item._id}>
                  <Accordion.Header>{capitalize(item.eventType)}</Accordion.Header>
                  <Accordion.Body>
                    {item.sections.length > 0 ? (
                      <>
                        <div className="delete-icon-ad">
                          <ul>
                            {item.sections.map((section) => (
                              <li key={section._id}>
                                <strong>{section.sectionName}</strong>: {section.totalSongs}{" "}
                                {section.totalSongs === 1 ? "song" : "songs"}
                              </li>
                            ))}

                          </ul>
                          <button onClick={() => handleOpenDeleteModal(item?._id)}>
                            <img src={require("../Assets/Images/delete.svg").default} />
                          </button>
                        </div>
                      </>
                    ) : (
                      <p>No sections available for this event type.</p>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              )) : <NoData />}
            </Accordion>
          </div>
        </div>
        <div className="mt-0">
          <p className="pb-4"></p>
        </div>
      </Container>
      <AddPresetModal show={showModal} handleClose={handleCloseModal} />

      <DeleteModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDeletePreset}
        name={"Preset"}
      />
    </Layout>
  );
}
