import React from "react";
import RoutesPage from "./routes/Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Components/Loader";

function App() {
  return (
    <div className="App">
     <ToastContainer 
        limit={1} 
        autoClose={3000} 
        hideProgressBar={false} 
        newestOnTop={false} 
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Loader />
      <RoutesPage />
    </div>
  );
}

export default App;
