import { format, isValid } from "date-fns";


export function formatDateToDMY(dateString, type) {
  const date = new Date(dateString);

  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear();
  if (type) {
    return `${year}-${month}-${day}`;
  } else {
    return `${day}/${month}/${year}`;
  }
}

export function getFormattedTime(dateString) {
  const date = new Date(dateString);

  const options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const formatter = new Intl.DateTimeFormat("en-US", options);
  return formatter?.format(date);
}

export function formatDate(inputDate) {
  // Convert the input to a Date object if it's not already
  const date = new Date(inputDate);

  // Check if date is valid
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfWeek = daysOfWeek[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = monthsOfYear[date.getMonth()];

  // Get the appropriate suffix for the day of the month (st, nd, rd, th)
  const suffix =
    dayOfMonth % 10 === 1 && dayOfMonth !== 11
      ? "st"
      : dayOfMonth % 10 === 2 && dayOfMonth !== 12
        ? "nd"
        : dayOfMonth % 10 === 3 && dayOfMonth !== 13
          ? "rd"
          : "th";

  // Check if the provided date is today
  const today = new Date();
  const isToday = date.toDateString() === today.toDateString();

  return `${dayOfWeek}, ${dayOfMonth}${suffix} ${month}${isToday ? " (Today)" : ""
    }`;
}

export function genderfunc(val) {
  if (val == 0) {
    return "Male";
  } else if (val == 1) {
    return "Female";
  } else if (val == 2) {
    return "Unisex";
  } else {
    return "-";
  }
}

export const formattedDate = (dateString) => {
  if (!dateString) return "-";
  const date = new Date(dateString);

  const options = { year: "numeric", month: "short", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

export function formatYearAndMonth(dateString) {
  if (!dateString) return "-";
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'short' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
}



export const capitalize = (str) => {
  if (typeof str !== 'string' || str.length === 0) {
    return 'N/A';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};


export const eventType = {
  "ceception": "Reception",
  "ceremony": "Ceremony",
  "registrar_singing": "Registrar Singing",
};


export const formatEventDetails = (eventDate, eventTime) => {
  try {
    const date = new Date(eventDate);
    const fromTime = new Date(eventTime.from);
    const toTime = new Date(eventTime.to);

    // If you want to display the time in UTC, convert the date to UTC by using the date's UTC components
    const adjustedFromTime = new Date(Date.UTC(fromTime.getUTCFullYear(), fromTime.getUTCMonth(), fromTime.getUTCDate(), fromTime.getUTCHours(), fromTime.getUTCMinutes(), fromTime.getUTCSeconds()));
    const adjustedToTime = new Date(Date.UTC(toTime.getUTCFullYear(), toTime.getUTCMonth(), toTime.getUTCDate(), toTime.getUTCHours(), toTime.getUTCMinutes(), toTime.getUTCSeconds()));

    const formatTime = (date) => {
      const hours = date.getUTCHours(); // Ensure we're using UTC time
      const minutes = date.getUTCMinutes(); // Ensure we're using UTC time
      const isPM = hours >= 12;

      const hour12 = hours % 12 || 12;
      const minuteFormatted = minutes < 10 ? `0${minutes}` : minutes;

      const ampm = isPM ? 'PM' : 'AM';

      return `${hour12}:${minuteFormatted} ${ampm}`;
    };

    const formattedDate = format(date, "EEEE, MMM dd, yyyy").replace(",", "");
    const formattedTime = `${formatTime(adjustedFromTime)} - ${formatTime(adjustedToTime)}`;

    return { formattedDate, formattedTime };
  } catch (error) {
    console.error("Error formatting event details:", error.message);
    return { formattedDate: "-", formattedTime: "-" };
  }
};

export const dateOfBookingEventWise = (eventDates) => {
  if (!Array.isArray(eventDates) || eventDates.length === 0) {
    return "-";
  }

  const validDates = eventDates
    .map(date => new Date(date))
    .filter(date => !isNaN(date));

  if (validDates.length === 0) {
    return "-";
  }

  const earliestDate = validDates.reduce((min, date) =>
    date < min ? date : min
  );

  return new Intl.DateTimeFormat("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  }).format(earliestDate);
};

export const formatTimeCalenderEvent = (date) => {
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const isPM = hours >= 12;

  const hour12 = hours % 12 || 12;
  const minuteFormatted = minutes < 10 ? `0${minutes}` : minutes;

  const ampm = isPM ? 'PM' : 'AM';

  return `${hour12}:${minuteFormatted} ${ampm}`;
};

export const convertTo12HourFormat = (time) => {
  if (!time) return '';
  const [hours, minutes] = time.split(':');
  let hour = parseInt(hours, 10);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  hour = hour % 12;
  hour = hour ? hour : 12;
  const formattedTime = `${hour}:${minutes} ${ampm}`;
  return formattedTime;
};

export function formatDateTime(inputDate) {
  const date = new Date(inputDate);
  const now = new Date();

  // Helper function to format time as `3:04 PM`
  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const amPm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for AM/PM format
    return `${formattedHours}:${minutes} ${amPm}`;
  };

  // Check if the input date is today
  const isToday =
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() === now.getDate();

  if (isToday) {
    return formatTime(date);
  }

  // Check if the input date is yesterday
  const yesterday = new Date(now);
  yesterday.setDate(yesterday.getDate() - 1);

  const isYesterday =
    date.getFullYear() === yesterday.getFullYear() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getDate() === yesterday.getDate();

  if (isYesterday) {
    return `Yesterday ${formatTime(date)}`;
  }

  // For earlier dates, return as `MM/DD/YYYY HH:MM AM/PM`
  const formattedDate = date.toLocaleDateString(); // `MM/DD/YYYY` format
  return `${formattedDate} ${formatTime(date)}`;
}