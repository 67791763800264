import { createSlice } from "@reduxjs/toolkit";
import { getSongs } from "../Actions/songs";

const initialState = {
    loading: false,
    songsList: [],

}

const SongsSlice = createSlice({
    name: "songs",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getSongs.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.success = true;
                state.songsList = payload.data;
            } else {
                state.success = false;
                state.error = payload?.message
            }
        })

        // ========================================================================


    },
});

export default SongsSlice.reducer;