import React from "react";
import { useNavigate } from "react-router-dom";

const SearchComponent = ({ heading, pathname }) => {
  const navigate = useNavigate();
  const [debounceTimer, setDebounceTimer] = React.useState(null);

  const handleInputChange = (event) => {
    const value = event.target.value;

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const timer = setTimeout(() => {
      if (value) {
        navigate(`${pathname}?search=${value}`);
      } else {
        navigate(pathname);
      }
    }, 1000);

    setDebounceTimer(timer);
  };

  return (
    <div className="search-container">
      <input
        type="text"
        className="w-100"
        placeholder={`Search ${heading}...`}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default SearchComponent;
