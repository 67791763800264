import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Formik, Field, ErrorMessage, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { sendForgetPasswordMail } from "../Redux/Actions/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
});

export default function ForgotPassword() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSubmit = (values) => {
    console.log("Form submitted with email:", values.email);
if(values.email){
  dispatch(sendForgetPasswordMail({email :  values.email})).then((res) => {
    console.log(res, "dfgdfgdfgretert")
    if(res?.payload?.statusCode == 200) {
      toast.success("Email has been sent.")
      navigate("/")
    } else {
      toast.error(res?.payload?.message)
    }
  })
}
  };

  return (
    <div className="p-3">
      <Container fluid>
        <div className="row sign-banner-part">
          <Col lg={6} className="p-0">
            <div className="otp-banner-img"></div>
          </Col>
          <Col lg={6}>
            <div className="right-banner-part">
              <div className="login-cmn-box">
                <div className="login-box-inner-wrap">
                  <div className="login-logo">
                    <img src={require("../Assets/Images/dark-logo.png")} />
                  </div>
                  <h2>Forgot Password</h2>
                  <p className="mb-0">Please enter your registered email</p>

                  {/* Formik Form */}
                  <Formik
                    initialValues={{ email: "" }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ handleSubmit }) => (
                      <FormikForm onSubmit={handleSubmit}>
                        <div className="form-set">
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email ID</Form.Label>
                            <Field
                              name="email"
                              type="email"
                              className="form-control"
                              placeholder="Enter your Registered Email"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>
                        </div>

                        {/* Submit Button */}
                        <button
                          type="submit"
                          className="submit forgot-btn"
                        >
                          Submit
                        </button>
                      </FormikForm>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  );
}
