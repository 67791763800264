import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Layout from "../Components/Layout/Layout";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, ErrorMessage, Form as FormikForm } from "formik";
import * as Yup from "yup";
import AdminActions from "../Redux/Actions/AdminActions";
import { toast } from "react-toastify";
import { startstopLoading } from "../Redux/Reducers/globalSlice";
import NoData from "../Components/NoData";
import { uploadFile } from "../Redux/Actions/auth";
import DeleteModal from "../Components/CommanModals/DeleteModal";

export default function Resources() {
  const { resources } = useSelector((state) => state.adminAuth)
  const dispatch = useDispatch()
  const location = useLocation();

  const [show, setShow] = useState(false);
  const [file, setFile] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [presetToDelete, setPresetToDelete] = useState(null);

  const totalEntries = resources?.total ?? 0;
  const entriesPerPage = 10;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  const validationSchema = Yup.object({
    title: Yup.string().required("title is required"),
  });

  const initialValues = {
    title: "",
  };

  const handleSubmit = async (values) => {
    if (!file) return toast.error('File required')
    let formData = new FormData()
    formData.append('file', file)
    let document = ''
    if (file) {
      const apiRes = await dispatch(uploadFile(formData))
      if (apiRes) {
        document = apiRes.payload.data.url
      }
    }
    let data = {
      ...values,
      document
    }
    try {
      await dispatch(AdminActions.createResource(data)).then(() => { Initialize() })
    } catch (error) {
      toast.error(error);
    }
  };

  const handleOpenDeleteModal = (id) => {
    setPresetToDelete(id);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleDeletePreset = () => {
    dispatch(AdminActions.deleteResource({ id: presetToDelete })).then((res) => {
      if (res?.payload?.statusCode == 200) {
        Initialize()
      }
      handleCloseDeleteModal();
    })
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const Initialize = async () => {
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get("search") || "";
    const queryParams = searchQuery ? { search: searchQuery } : { page: currentPage, limit: 10 };
    dispatch(startstopLoading(true));
    await dispatch(AdminActions.getResource(queryParams)).then((res) => {
      dispatch(startstopLoading(false));
      handleClose()
    });
  }

  useEffect(() => {
    Initialize()
  }, [location.search, location.pathname, currentPage]);

  return (
    <Layout>
      <Container fluid>
        <div className="comn-table-title">
          <h3>
            Resources <span>({resources?.length})</span>
          </h3>
          <button onClick={() => {
            setFile(null)
            handleShow()
          }}>Add New</button>
        </div>
        <div className="songlist-selection mt-3">
          <div className="my-profile-title">
            <h4>Resources</h4>
          </div>
          <hr />
          <div className="resources-card-list">
            {resources?.length ? <ul>
              {resources.map((resourceRes) =>
                <li>
                  <h4>{resourceRes.title}</h4>
                  <button onClick={() => handleOpenDeleteModal(resourceRes?._id)} >Delete</button>
                </li>
              )}
            </ul>
              :
              <NoData />
            }
          </div>
        </div>
        <div className="mt-0">
          <p className="pb-4"></p>
        </div>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">Add Resource</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ handleSubmit, errors, isValid }) => (
                <FormikForm onSubmit={handleSubmit}>
                  <div className="modal-cmn-form-set">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Document Title</Form.Label>
                      <Field
                        name="title"
                        type="text"
                        className="form-control"
                        placeholder="Enter document title"
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="text-danger"
                      />
                    </Form.Group>
                  </div>
                  <div className="docu-upload-btn">
                    <input type="file" onChange={(e) => setFile(e.target.files[0])} />
                    <button>Upload File</button>
                    {file && <p className="mt-2 text-bold">{file.name}</p>}
                  </div>
                  <Modal.Footer className="border-none justify-content-center">
                    <button className="secondary" onClick={handleClose} type="button" >
                      Cancel
                    </button>
                    <button className="primary"
                      type="submit"
                    >
                      Add
                    </button>
                  </Modal.Footer>
                </FormikForm>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <DeleteModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDeletePreset}
        name={"Resource"}
      />
    </Layout >
  );
}
