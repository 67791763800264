import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Form, Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useState } from "react";
import { Formik, Field, ErrorMessage, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import AdminActions from "../Redux/Actions/AdminActions";
import { useLocation } from "react-router-dom";
import { startstopLoading } from "../Redux/Reducers/globalSlice";
import NoData from "../Components/NoData";
import PaginationComponent from "../Components/Pagination";
import DeleteModal from "../Components/CommanModals/DeleteModal";

export default function Administrators() {
  const { administrators } = useSelector((state) => state.adminAuth)
  const dispatch = useDispatch()
  const location = useLocation();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [adminToDelete, setAdminToDelete] = useState(null);
  const [selectedAdmin, setSelectedAdmin] = useState(null);

  const totalEntries = administrators?.total ?? 0;
  const entriesPerPage = 10;
  const totalPages = Math.ceil(totalEntries / entriesPerPage);

  const validationSchema = Yup.object({
    fullName: Yup.string().required("full name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("email is required"),
  });

  const initialValues = {
    fullName: selectedAdmin?.fullName || "",
    email: selectedAdmin?.email || "",
    permission: selectedAdmin?.fullPermission ? 1 : 0
  };


  const handleOpenDeleteModal = (id) => {
    setAdminToDelete(id);
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleDeleteSong = () => {
    dispatch(AdminActions.deleteAdministrator({ id: adminToDelete })).then((res) => {
      if (res?.payload?.statusCode == 200) {
        Initialize()
        toast.success("Deleted Successfully")
      } else {
        toast.error(res?.payload?.message)
      }
      handleCloseDeleteModal();
    })
  }
  const handleEditSongs = (data) => {
    setSelectedAdmin(data);
    handleShow();
  }

  const handleSubmit = async (values) => {
    debugger
    // (values.permission == '0' || !values.permission) ? values['readOnlyPermission'] = false
    values['fullPermission'] = (values.permission == '0' || !values.permission) ? false : true
    delete values['permission']
    if (selectedAdmin?._id) {
      values['id'] = selectedAdmin?._id
    }
    try {
      if (selectedAdmin?._id) {
        await dispatch(AdminActions.editAdministrator(values)).then(() => {
          Initialize()
        })
      } else {
        await dispatch(AdminActions.createAdministrator(values)).then(() => {
          Initialize()
        })
      }
      handleClose()
    } catch (error) {
      toast.error(error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const Initialize = async () => {
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get("search") || "";
    const queryParams = searchQuery ? { search: searchQuery } : { page: currentPage, limit: 10 };
    dispatch(startstopLoading(true));
    await dispatch(AdminActions.getAdministrator(queryParams)).then((res) => {
      dispatch(startstopLoading(false));
    });
  }

  useEffect(() => {
    Initialize()
  }, [location.search, location.pathname, currentPage]);

  return (
    <Layout>
      <Container fluid>
        <div className="comn-table-title">
          <div>
            <h3>
              Administrators <span>({administrators?.admin?.length})</span>
            </h3>
          </div>
          <button onClick={() => {
            setSelectedAdmin(null)
            handleShow()
          }}>Add New</button>
        </div>
        <Row>
          <Col lg={12}>
            <div className="filters">
              <div className="inner-filter-field">
                <div class="table-responsive">
                  <Table size="sm" className="table-cmn">
                    <thead>
                      <tr>
                        <th>
                          <div className="user-checkbox">Name</div>
                        </th>
                        <th>Email</th>
                        <th>Permissions</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {administrators?.admin?.length ? administrators.admin.map((administratorRes) =>
                        <tr>
                          <td>
                            <div className="first-user">
                              <div className="user-profile">{administratorRes?.fullName}</div>
                            </div>
                          </td>
                          <td>{administratorRes?.email}</td>
                          <td>{administratorRes?.fullPermission ? 'Full Access' : 'Restricted'}</td>

                          <td>
                            <div className="d-flex gap-2">
                              <div className="td-txt-bg" onClick={() => handleEditSongs(administratorRes)} style={{ cursor: "pointer" }}>Edit</div>
                              <div className="td-txt-bg" onClick={() => handleOpenDeleteModal(administratorRes._id)} style={{ cursor: "pointer" }}>Delete</div>
                            </div>
                          </td>
                          {/* <td>
                            <div className="action">
                              <img
                                src={
                                  require("../Assets/Images/options.svg").default
                                }
                              />
                            </div>
                          </td> */}
                        </tr>
                      ) :
                        <NoData />
                      }
                    </tbody>
                  </Table>
                  {administrators?.total > 1 && (
                    <PaginationComponent
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                      totalEntries={totalEntries}
                      entriesPerPage={entriesPerPage}
                    />)}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div class="mb-0"><p class="pb-4"></p></div>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-none justify-content-center">
          <Modal.Title className="mt-3">{selectedAdmin?._id ? 'Edit' : 'Add'} Administrator</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          {/* <Col lg={4}> */}
          <div className="">
            <div className="inner-filter-field">
              <div className="add-administrator mb-3">
                <div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                  >
                    {({ handleSubmit, errors, isValid }) => (
                      <FormikForm onSubmit={handleSubmit}>
                        <div className="modal-cmn-form-set">
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Full Name</Form.Label>
                            <Field
                              name="fullName"
                              type="text"
                              className="form-control"
                              placeholder="Enter name"
                            />
                            <ErrorMessage
                              name="fullName"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email ID</Form.Label>
                            <Field
                              name="email"
                              type="email"
                              className="form-control"
                              placeholder="Enter email"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-danger"
                            />
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Permissions</Form.Label>
                            <Field as="select" aria-label="Default select example" name="permission" className='form-control'>
                              <option value={0}>Restricted</option>
                              <option value={1}>Full Access</option>
                            </Field>
                          </Form.Group>
                        </div>
                        <div className="add-administrator-action">
                          <button className="secondary" type="button" onClick={handleClose}>
                            Cancel
                          </button>
                          <button className="primary" type="submit">
                            {selectedAdmin?._id ? 'Edit' : 'Add'}
                          </button>
                        </div>
                      </FormikForm>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          {/* </Col> */}
        </Modal.Body>
        {/* <Modal.Footer className="border-none justify-content-center">
          <button className="secondary" onClick={handleClose}>
            Close
          </button>
          <button className="primary" onClick={handleClose}>
            Add
          </button>
        </Modal.Footer> */}
      </Modal>
      <DeleteModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDeleteSong}
        name={"administrator"}
      />
    </Layout >
  );
}
