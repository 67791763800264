import React from 'react';
import { Modal } from 'react-bootstrap';

const DeleteModal = ({ show, handleClose, handleDelete, name }) => {
    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header className="border-none justify-content-center">
                <Modal.Title className="mt-3">Delete Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-0">
                <div className="mb-3 text-center">
                    <p>Are you sure you want to delete this {name}?</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="border-none justify-content-center">
                <button className="secondary" type="button" onClick={handleClose}>
                    No
                </button>
                <button className="primary" type="button" onClick={handleDelete}>
                    Yes
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteModal;
