import { createSlice } from "@reduxjs/toolkit";
import AdminActions from "../Actions/AdminActions";
import { toast } from "react-toastify";

const initialState = {
    loading: false,
    administrators: {},
    faqs: {},
    resources: {},
    clientEventsList: [],
    visibleRange: null,
    newUserLists: []
}

const adminActionSlice = createSlice({
    name: "adminActionSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(AdminActions.createAdministrator.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.loading = false
                toast.success(payload?.message)
            } else {
                toast.error(payload?.message)
                state.loading = false
            }
        })
        builder.addCase(AdminActions.createAdministrator.pending, (state, { payload }) => {
            state.loading = true
        })
        builder.addCase(AdminActions.createAdministrator.rejected, (state, { payload }) => {
            state.loading = false
            toast.error(payload?.message || "Something went wrong")
        })

        // ========================================================================

        builder.addCase(AdminActions.getAdministrator.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.loading = false
                state.administrators = payload.data
            } else {
                toast.error(payload?.message)
                state.loading = false
            }
        })
        builder.addCase(AdminActions.getAdministrator.pending, (state, { payload }) => {
            state.loading = true
        })
        builder.addCase(AdminActions.getAdministrator.rejected, (state, { payload }) => {
            state.loading = false
            toast.error(payload?.message || "Something went wrong")
        })

        // ========================================================================

        builder.addCase(AdminActions.createFaq.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.loading = false
                toast.success(payload?.message)
            } else {
                toast.error(payload?.message)
                state.loading = false
            }
        })
        builder.addCase(AdminActions.createFaq.pending, (state, { payload }) => {
            state.loading = true
        })
        builder.addCase(AdminActions.createFaq.rejected, (state, { payload }) => {
            state.loading = false
            toast.error(payload?.message || "Something went wrong")
        })

        // ========================================================================

        builder.addCase(AdminActions.getFaq.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.loading = false
                state.faqs = payload.data
            } else {
                toast.error(payload?.message)
                state.loading = false
            }
        })
        builder.addCase(AdminActions.getFaq.pending, (state, { payload }) => {
            state.loading = true
        })
        builder.addCase(AdminActions.getFaq.rejected, (state, { payload }) => {
            state.loading = false
            toast.error(payload?.message || "Something went wrong")
        })

        // ========================================================================

        builder.addCase(AdminActions.createResource.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.loading = false
                toast.success(payload?.message)
            } else {
                toast.error(payload?.message)
                state.loading = false
            }
        })
        builder.addCase(AdminActions.createResource.pending, (state, { payload }) => {
            state.loading = true
        })
        builder.addCase(AdminActions.createResource.rejected, (state, { payload }) => {
            state.loading = false
            toast.error(payload?.message || "Something went wrong")
        })

        // ========================================================================

        builder.addCase(AdminActions.getResource.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.loading = false
                state.resources = payload.data
            } else {
                toast.error(payload?.message)
                state.loading = false
            }
        })
        builder.addCase(AdminActions.getResource.pending, (state, { payload }) => {
            state.loading = true
        })
        builder.addCase(AdminActions.getResource.rejected, (state, { payload }) => {
            state.loading = false
            toast.error(payload?.message || "Something went wrong")
        })

        // ========================================================================

        builder.addCase(AdminActions.deleteResource.fulfilled, (state, { payload }) => {
            if (payload?.statusCode == 200) {
                state.loading = false
                toast.success(payload?.message)
            } else {
                toast.error(payload?.message)
                state.loading = false
            }
        })
        builder.addCase(AdminActions.deleteResource.pending, (state, { payload }) => {
            state.loading = true
        })
        builder.addCase(AdminActions.deleteResource.rejected, (state, { payload }) => {
            state.loading = false
            toast.error(payload?.message || "Something went wrong")
        })

        // ========================================================================

    },
});

export default adminActionSlice.reducer;